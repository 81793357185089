import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import { savedSearchDeleteApi } from "../../components/Api/ListingApi";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastPopup from "../../components/popup/ToastPopup";
function DeleteSearchPopup(props) {
    const { savedSearchDelete, toggleDeleteSearch, reload, deleteId } = props;
    const [success, setSucess] = useState(false);
    const [messages, setMessages] = useState("")
    const toggleSuccess = () => setSucess(p => !p);


    const deleteButton = async () => {

        let getParams = {
            id: deleteId._id
        }

        await savedSearchDeleteApi(getParams).then((res) => {
            toggleDeleteSearch()
            reload()
            setMessages(res.data.message)
            toggleSuccess()
            setTimeout(() => setSucess(), 3000)
        }
        ).catch((error) => {
            console.log(error)
        })
    }


    return (
        <div>
            <ToastContainer />
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />

            <Modal show={savedSearchDelete} onHide={toggleDeleteSearch} className='ModalDiv'>
                <div className="PopUpBorderDiv">

                    <Modal.Header closeButton className="team-popup-margin1 teampopup-title savedSearchshare ">
                        <Modal.Title className='form-field-label savedSearch'>Delete Saved Search</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin pb-0" >
                        <div>
                            <div className={style1.successtext}>
                                <>
                                    <p className={style1.accepttext}>Are you sure you want to delete your</p>
                                    <p className={style1.accepttext}>saved search : <span style={{ fontFamily: "DejaVuSansBold" }}>{deleteId?.name ? `"${deleteId.name}"` : `"${deleteId.city}"`}?</span></p>
                                </>
                            </div>
                        </div>
                        <Button className={style1.nobutton} onClick={toggleDeleteSearch}>
                            No
                        </Button>
                        <Button className={style1.yesbutton} onClick={deleteButton}>
                            Yes
                        </Button>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}
export default DeleteSearchPopup